<template>
  <div
    class="g-catalogue-footer__section"
    v-if="
      entity.collections && entity.collections.length > 0 && entity.collections[0].id != 0
    "
  >
    <h5 class="g-catalogue-footer__title">También podría interesarte...</h5>
    <div class="g-catalogue-footer__content">
      <div class="g-tags-slider">
        <!-- TODO linkar a la nova pàgina està pendent de productes relacionats-->

        <VueSlickCarousel v-bind="settingsCarouselTags" dotsClass="slick-dots">
          <div
            class="g-tags-slider__item"
            v-for="(collection, index) in entity.collections"
            :key="index"
          >
            <router-link
              class="badge badge--light badge--rounded badge--lg"
              :to="route(collection)"
              >{{ collection.name }}</router-link
            >
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "EntityCollections",
  components: { VueSlickCarousel },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      currentIndex: 0,
      settingsCarouselTags: {
        variableWidth: true,
        centerMode: false,
        infinite: false,
        dots: false,
        arrows: true,
        draggable: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    route(collection) {
      return {
        name: "collection",
        params: {
          slug: this.$formatUrlhelloBB(collection.name),
          id: collection.id,
        },
      };
    },
  },
};
</script>
